function Home() {
  return (
    
    
    <div>
      <h1>Welcome to Home Page</h1>
      <div className="page-content-home">
        <p>This is the home page.</p>
      </div>
    </div>
  );
}
export default Home;