function Contact() {
  return (
    <div>
      <h1>Contact Us</h1>
      <div className="page-content-contact">
        <p>This is the Contact Us page.</p>
      </div>
    </div>
  )
}
export default Contact;